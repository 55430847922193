
declare var PLATFORM_DOMAIN: any;
declare var CONNECT_DOMAIN: any;
import CloudActivationBackground from "@/components/landingPages/cloudActivation/CloudActivationBackground.vue";
import { Component, Vue } from "vue-property-decorator";
import { ContentNode } from "@/types/layout/contentNode";
import SimpleIconTitle from "@/components/common/SimpleIconTitle.vue";
import { CloudValidationResponse, storeErrorsMapping } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "EmailVerification",
  components: { CloudActivationBackground, SimpleIconTitle },
})
export default class EmailVerification extends Vue {
  title: string = "";
  icon: string = "";
  hasConnect: boolean = false;

  data: CloudValidationResponse = { status: -1, result: -1 };
  serverData: Array<ContentNode> = [];
  gotResponseFromStore: boolean = false;

  created() {
    this.$jfCloudActivations.getValidationData(false).then((data: CloudValidationResponse) => {
      this.data = data;
      if (data.products && data.products.includes("connect")) {
        this.data.instanceUrl = `${CONNECT_DOMAIN}/jfrog_registration_redirect?organization_name=${this.data.serverName}.${PLATFORM_DOMAIN}`;
      }
      this.setData();
    });
  }
  setData() {
    this.$jfUsers.logRequestHeaders("Email Verification headers");
    this.title = "Verification required";
    this.serverData = [
      {
        title: "Server Name",
        value: this.data.serverName,
        icon: "activation_server.svg",
      },
      {
        title: "Location",
        value: this.data.region,
        icon: "activation_location.svg",
      },
    ];
    switch (this.data.result) {
      case storeErrorsMapping.cloudValidation.alreadyValidated:
        this.gotResponseFromStore = true;
        this.title = "This email has been verified before";
        this.icon = "verification_ok.svg";
        break;
      case storeErrorsMapping.cloudValidation.tokenIsInvalid:
        this.gotResponseFromStore = true;
        this.title = "Invalid token";
        this.icon = "verification_error.svg";
        break;
      case storeErrorsMapping.cloudValidation.accountBlocked:
        this.gotResponseFromStore = true;
        this.title = "Your account is blocked";
        this.icon = "verification_error.svg";
        break;
    }

    if (this.data.products && this.data.products.includes("connect")) {
      this.hasConnect = true;
    }
  }
  verify() {
    this.$jfCloudActivations.getValidationData(true).then((data: CloudValidationResponse) => {
      this.data = data;
      if (data.products && data.products.includes("connect")) {
        this.data.instanceUrl = `${CONNECT_DOMAIN}/jfrog_registration_redirect?organization_name=${this.data.serverName}.${PLATFORM_DOMAIN}`;
      }
      this.gotResponseFromStore = true;
      switch (this.data.result) {
        case 0:
          this.title = "Email verified successfully";
          this.icon = "verification_ok.svg";
          break;
        case storeErrorsMapping.cloudValidation.alreadyValidated:
          this.title = "This email has been verified before";
          this.icon = "verification_ok.svg";
          break;
        default:
          this.title = "We couldn't verify your email";
          this.icon = "verification_error.svg";
          break;
      }
    });
  }

  iconSrc(): string | null {
    if (this.icon) {
      try {
        return this.$jfImages.get(this.icon);
      } catch (e) {
        this.$log.error("Failed to retrieve icon : " + this.icon);
      }
    }
    return null;
  }

  get showContactMessage() {
    return ([400, 403].includes(this.data.status) && this.data.result !== 990) || this.data.result === -1;
  }
  get isMobile() {
    return this.$mq === "mobile";
  }

  get wrapperClasses() {
    const classes: string[] = [this.$mq];
    if (this.isMobile) {
      classes.push("fxCol");
    }
    return classes;
  }
}
